import React, { Component, Fragment } from 'react';
import { Route, Switch } from "react-router-dom";

import { ScrollToTop, HundredVH, Preloader, Header, Navigation } from './components';
import { Home, Gallery, Exhibitions, About, Contact } from './sections';

import './style.css';

class App extends Component {
  render() {
    return (
      <Fragment>
        <ScrollToTop />
        <HundredVH />
        <Header />
        <Navigation />
        <div className="body">
          <Preloader disabled>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/exhibitions" component={Exhibitions} />
              <Route path="/gallery/:id?/:name?" component={Gallery} />
              <Route path="/about" component={About} />
              <Route path="/contact" component={Contact} />
            </Switch>
          </Preloader>
        </div>
      </Fragment>
    );
  }
}

export default App;
