const cache = {};
const CACHE_TTL = 5 * 30 * 1000;

function fetchCache(url, options = {}) {
  const { method } = options;
  if (method && method !== 'GET') {
    return fetch(url, options);
  }

  const key = url;
  const entry = cache[key];

  if (entry && entry.ttl > Date.now()) {
    return Promise.resolve(entry.data);
  }

  const promise = fetch(url, options)
    .then(res => res.json())
    .then((data) => {
      cache[key].data = data;
      return data;
    });

  cache[key] = {
    data: promise,
    ttl: Date.now() + CACHE_TTL
  };

  return promise;
}

export default fetchCache;
