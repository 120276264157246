import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import idx from 'idx';
import cx from 'classnames';
import { throttle } from 'throttle-debounce';

import { FullscreenGallery, Image } from 'components';
import { urls } from 'utils/api';
import fetch from 'utils/fetch';

import styles from './Gallery.module.css';
import animations from './animations.module.css';

const mediaFields = [
  'acf',
  'id',
  'date',
  'slug',
  'featured_image'
];

class Gallery extends Component {

  state = {
    pieces: [],
    techniques: {},
    filters: {},
    years: [],
    flipSort: true,
    filtersVisible: false,
    selectedPieceIdx: null
  }

  componentDidMount() {
    this.updateMenuFilterDb = throttle(150, this.updateMenuFilter);
    window.addEventListener('scroll', this.updateMenuFilterDb);
    window.addEventListener('resize', this.updateMenuFilterDb);
    window.scrollTo(0, 0);
    
    this.prepareMockData();

    Promise
      .all([
        fetch(`${urls.techniques}?per_page=100&fields=id,name,slug`),
        this.fetchGallery()
      ])
      .then(this.prepareData)
      .then(this.setUrl);
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
    this.updateMenuFilterDb.cancel();
    window.removeEventListener('scroll', this.updateMenuFilterDb);
    window.removeEventListener('resize', this.updateMenuFilterDb);
  }

  componentDidUpdate({ match: prevMatch }, { pieces: prevPieces }) {
    const { pieces } = this.state;
    const { match } = this.props;

    if (pieces.length !== prevPieces.length) {
      this.updateMenuFilter(null, true);
    }

    if (prevMatch.params.id && !match.params.id) {
      this.setState({ selectedPieceIdx: null });
    } else if (!prevMatch.params.id && match.params.id) {
      this.setUrl();
    }
  }

  fetchGallery = (page = 1, results = []) => {
    return fetch(`${urls.gallery}?per_page=100&page=${page}&_envelope&fields=${mediaFields.join(',')}`)
      .then(({ headers, body }) => {
        results = [...results, ...body];
        if (headers['X-WP-TotalPages'] > page) {
          return this.fetchGallery(page + 1, results);
        }
        return results;
      });
  }

  prepareMockData = () => {
    this.setState({
      pieces: [...Array(20)].map(() => ({
        technique: '',
        images: {},
        year: '',
        mock: true
      }))
    });
  }

  prepareData = ([ techData, galleryData ]) => {
    const techniques = techData.reduce((acc, { id, name, slug }) => {
      acc[id] = {
        name,
        slug
      };
      return acc;
    }, {});

    const pieces = galleryData
      .map(entry => ({
        ...entry.acf,
        year: parseInt(entry.acf.date.substr(-4)),
        id: entry.id,
        slug: entry.slug,
        technique: techniques[entry.acf.technique],
        images: this.getImageUrls(entry.featured_image)
      }))
      .sort((a, b) => new Date(b.date) - new Date(a.date));

    this.setState({ pieces });
  }

  setUrl = () => {
    const { pieces } = this.state;
    const { match: { params : { id } } } = this.props;

    // eslint-disable-next-line eqeqeq
    const found = id && pieces.findIndex(piece => piece.id == id);
    if (found > -1) {
      this.setState({ selectedPieceIdx: found });
    }
  }

  getImageUrls = imageData => {
    if (!imageData) {
      return;
    }

    const file = imageData.file;
    const baseUrl = imageData.base_url;
    const folderUrl = file.substring(0, file.lastIndexOf('/'));

    return {
      fullUrl: `${baseUrl}/${file}`,
      thumbUrl: `${baseUrl}/${folderUrl}/${idx(imageData, _ => _.sizes.thumbnail.file)}`
    };
  }

  enterFullscreen = selectedPieceIdx => this.setState({ selectedPieceIdx, fromGallery: true })

  exitFullscreen = () => {
    if (this.state.fromGallery) {
      this.props.history.goBack();
    } else {
      this.props.history.replace('/gallery');
    }
    this.setState({ selectedPieceIdx: null, fromGallery: false });
  }

  updateFilters = slug => {
    this.setState(state => {
      const filters = { ...state.filters };
      if (state.filters[slug]) {
        delete filters[slug];
      } else {
        filters[slug] = true;
      }
      return {
        filters
      };
    }, () => this.updateMenuFilter(null, true));
  }

  clearFilters = () => {
    this.setState({ filters: {}, filtersVisible: false }, this.updateMenuFilter);
  }

  getRef = ref => {
    if (!ref) {
      return;
    }

    this.galleryElt = ref;

    const checkFn = (tries = 0) => {
      this.timeout = setTimeout(() => {
        if (tries < 5 && !this.galleryElt.childNodes.length) {
          return checkFn(tries++);
        }
        this.updateMenuFilter();
      }, 250);
    };

    checkFn();
  }

  prevScroll = window.scrollY
  updateMenuFilter = (evt, forced) => {
    if (!this.galleryElt || (!forced && this.prevScroll === window.scrollY)) {
      return;
    }
    this.prevScroll = window.scrollY;

    if (!forced && this.state.filtersVisible) {
      this.setState({ filtersVisible: false });
    }

    if (this.props.portraitMode) {
      return;
    }

    const height = window.innerHeight;
    let minIdx = null, maxIdx = -1;

    this.galleryElt.childNodes.forEach((elt, idx) => {

      const bounds = elt.getBoundingClientRect();

      if (idx === 0 && !this.baseTop) {
        this.baseTop = bounds.top;
      }

      const eltTop = bounds.top;
      const eltBot = bounds.top + bounds.height;
      const visible = ((eltTop <= height) && (eltBot >= this.baseTop));

      if (visible) {
        if (minIdx === null) {
          minIdx = idx;
        } else if (idx > maxIdx) {
          maxIdx = idx;
        }
      }
    });

    this.setState({ years: [minIdx, maxIdx] });
  }

  scrollPieceToView = idx => {
    const bounds = this.galleryElt.childNodes[idx].getBoundingClientRect();
    window.scrollTo(0, window.scrollY + bounds.top - this.baseTop);
  }

  flipSort = () => this.setState(({ flipSort, pieces }) => ({
    flipSort: !flipSort,
    pieces: pieces.slice().reverse()
  }))

  render() {
    const {
      pieces,
      filters,
      selectedPieceIdx,
      flipSort,
      filtersVisible
    } = this.state;

    const techs = pieces.reduce((acc, val) => {
      acc[val.technique.slug] = val.technique.name;
      return acc;
    }, {});

    const techniques = Object
      .keys(techs)
      .map((key, idx) => (
        <div
          key={idx}
          onClick={() => this.updateFilters(key)}
          className={cx(styles.technique, {
            [styles.active]: filters[key]
          })}
        >
          {techs[key]}
        </div>
      ));

    const filteredPieces = pieces.filter(piece => !Object.keys(filters).length || filters[piece.technique.slug]);

    const images = filteredPieces.map((piece, idx) => (
      <div className={cx(styles.item, {[styles.mock]: piece.mock})} key={idx}>
        <Image
          className={styles.image}
          src={piece.images.thumbUrl}
          alt={piece.name}
          onClick={() => this.enterFullscreen(idx)}
        />
      </div>
    ));

    const yearsList = Object
      .keys(filteredPieces.reduce((acc, val) => {
        acc[val.year] = true;
        return acc;
      }, {}))
      .sort((a, b) => flipSort ? b - a : a - b)
      .map((year, index) => {
        const { years: [min, max] } = this.state;
        const minVis = idx(filteredPieces[flipSort ? max : min], _ => _.year);
        const maxVis = idx(filteredPieces[flipSort ? min : max], _ => _.year);
        const visible = year >= minVis && year <= maxVis;
        // eslint-disable-next-line eqeqeq
        const pieceIdx = filteredPieces.findIndex(piece => piece.year == year);
        return (
          <div
            key={year}
            className={cx(styles.year, {[styles.active]: visible})}
            onClick={() => this.scrollPieceToView(pieceIdx)}
          >
            {year}
          </div>
        );
      });

    return (
      <Fragment>
        <CSSTransition
          in={selectedPieceIdx !== null}
          timeout={{
            enter: 500,
            exit: 250
          }}
          classNames={{ ...animations }}
          mountOnEnter
          unmountOnExit
        >
          <FullscreenGallery
            pieces={filteredPieces}
            selectedIdx={selectedPieceIdx}
            onEscape={this.exitFullscreen}
          />
        </CSSTransition>
        <div className={cx(styles.showFilters, {[styles.collapse]: filtersVisible})}>
          <div onClick={() => this.setState({ filtersVisible: !filtersVisible })}>{'⟩'}</div>
        </div>
        <div className={cx(styles.techWrapper, {[styles.opened]: filtersVisible})}>
          <div className={styles.techniques}>
            {techniques}
          </div>
          {Object.keys(filters).length > 0 && (
            <div className={styles.clear} onClick={this.clearFilters} title="Clear active filters">×</div>
          )}
        </div>
        <div>
          <div className={styles.options}>
            <div className={cx(styles.sort, {[styles.inverted]: !flipSort})} onClick={this.flipSort}>
              <span>▽</span>
              <span>▲</span>
            </div>
            <div className={styles.years}>
              {yearsList}
            </div>
          </div>
          <div className={styles.gallery} ref={this.getRef}>
            {images}
          </div>
        </div>
      </Fragment>
    );
  }

}

const mapStateToProps = ({ window }) => ({
  portraitMode: idx(window, _ => _.orientation.type) === 'portrait-primary'
})

export default connect(mapStateToProps)(Gallery);
