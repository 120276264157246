import { debounce } from 'throttle-debounce';

import {
  updateViewportSize,
  updateViewportOrientation
} from './actions';

function getWindowOrientation() {
  if (window.screen.orientation) {
    const { type, angle } = window.screen.orientation;
    return {
      type,
      angle
    }
  }
  if (window.orientation !== undefined) {
    const angle = window.orientation;
    const angleToOrientation = {
      '0': 'portrait-primary',
      '180': 'portrait-secondary',
      '-90': 'landscape-primary',
      '90': 'landscape-secondary'
    };
    return {
      type: angleToOrientation[angle],
      angle
    }
  }
  return {};
}

export default function initEventListeners(store) {
  store.dispatch(updateViewportSize(window.innerWidth, window.innerHeight));
  window.addEventListener('resize', debounce(300, () => {
    store.dispatch(updateViewportSize(window.innerWidth, window.innerHeight));
  }));

  const { type, angle } = getWindowOrientation();
  store.dispatch(updateViewportOrientation(type, angle));
  window.addEventListener('orientationchange', () => {
    const { type, angle } = getWindowOrientation();
    store.dispatch(updateViewportOrientation(type, angle));
  });
}
