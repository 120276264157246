import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import cx from 'classnames';
import { withRouter } from 'react-router-dom';
import { HamburgerMinus as Hamburger } from 'react-animated-burgers';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

import styles from './Navigation.module.css';

function Navigation({ history }) {

  const [opened, setOpened] = useState(false);

    useEffect(() => {
      return history.listen(() => {
        setOpened(false);
      });
    }, []);

    const menuRef = React.createRef();
    
    useEffect(() => {
      if (opened) {
        disableBodyScroll(menuRef.current);
      } else {
        clearAllBodyScrollLocks();
      }
      return clearAllBodyScrollLocks;
    }, [opened]);

  const menu = [
    ["/", "Home"],
    ["/gallery", "Gallery"],
    ["/exhibitions", "Exhibitions"],
    ["/about", "About"],
    ["/contact", "Contact"]
  ];

  return (
    <div className={cx(styles.navigation, {[styles.opened]: opened})} ref={menuRef}>
      <Hamburger
        className={styles.hamburger}
        isActive={opened}
        toggleButton={() => setOpened(!opened)}
      />
      <div className={styles.items}>
        {menu.map(([path, title], idx) => (
          <NavLink
            key={idx}
            activeClassName={styles.active}
            exact={!idx}
            to={path}
          >
            {title}
          </NavLink>
        ))}
      </div>
    </div>
  );

}

export default withRouter(Navigation);
