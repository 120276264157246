import React, { Component } from 'react';

import { urls, baseUrl } from 'utils/api';
import fetch from 'utils/fetch';
import { Image } from 'components';

import styles from './Exhibitions.module.css';

class Exhibitions extends Component {

  state = {
    exhibitions: []
  }

  componentDidMount() {
    fetch(urls.exhibitions).then(this.prepareData);
  }

  prepareData = data => {
    if (!data) {
      return;
    }

    const exhibitions = data.map(entry => entry.acf);
    const slugs = exhibitions.map(exhib => exhib.gallery[0] && exhib.gallery[0].post_name);

    return fetch(`${urls.galleries}?slug=${slugs.join(',')}`)
      .then(galleries => {
        return this.setState({
          exhibitions,
          galleries
        });
      });
  }

  render() {
    const { exhibitions, galleries } = this.state;

    const exhibs = exhibitions.map((exhib, idx) => {
      return (
        <div key={idx} className={styles.exhibition}>
          <div className={styles.title}>
            <span className={styles.name}>
              {`${exhib.title} : ${exhib.date.substr(-4)}`}
            </span> {`Vernissage at ${exhib.place}, ${exhib.location}`}
          </div>
          <div className={styles.images}>
            {galleries[exhib.gallery[0].post_name].map((image, idx) => (
              <div key={idx} className={styles.imageContainer}>
                <Image
                  key={image.image_url}
                  src={`${baseUrl}/${image.image_url}`}
                  alt={image.alt}
                />
              </div>
            ))}
          </div>
        </div>
      );
    });

    return (
      <div className={styles.exhibitions}>
        {exhibs}
      </div>
    );
  }

}

export default Exhibitions;
