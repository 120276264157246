const urls = {
  home: 'https://empa.se/wordpress/wp-json/wp/v2/posts?slug=home&fields=acf',
  about: 'https://empa.se/wordpress/wp-json/wp/v2/posts?slug=about&fields=acf',
  exhibitions: 'https://empa.se/wordpress/wp-json/wp/v2/exhibitions?fields=acf',
  gallery: 'https://empa.se/wordpress/wp-json/wp/v2/pieces',
  techniques: 'https://empa.se/wordpress/wp-json/wp/v2/techniques',
  contact: 'https://empa.se/wordpress/wp-json/wp/v2/posts?slug=contact&fields=acf',
  galleries: 'https://empa.se/wordpress/wp-json/custom/v1/galleries',
  email: '/php/contact.php'
}

const baseUrl = process.env.NODE_ENV === 'development'
  ? 'https://empa.se/wordpress'
  : '/wordpress';

export {
  urls,
  baseUrl
};
