import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

import { Image } from 'components';

import styles from './FullscreenGallery.module.css';

class FullscreenGallery extends Component {

  static propTypes = {
    pieces: PropTypes.array,
    selectedIdx: PropTypes.number,
    onEscape: PropTypes.func,
    windowWidth: PropTypes.number.isRequired
  }

  state = {
    selectedIdx: this.props.selectedIdx
  }

  targetRef = React.createRef()

  componentDidMount() {
    document.addEventListener('keydown', this.keyPressed);
    disableBodyScroll(this.targetRef.current);
    this.setUrl(true);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.keyPressed);
    clearAllBodyScrollLocks();
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedIdx } = this.state;
    if (prevState.selectedIdx !== selectedIdx) {
      this.setUrl();
    }
  }

  setUrl = (addToHistory) => {
    const { history, pieces } = this.props;
    const { selectedIdx } = this.state;
    const piece = pieces[selectedIdx];
    const path = `/gallery/${piece.id}/${piece.slug}`;

    if (addToHistory) {
      history.push(path);
    } else {
      history.replace(path);
    }
  }

  keyPressed = evt => {
    switch (evt.key) {
      case 'Escape':
        this.props.onEscape();
        break;
      case 'ArrowLeft':
        this.goPrevious();
      break;
      case 'ArrowRight':
        this.goNext();
      break;
      default:
    }
  }

  goPrevious = () => {
    const { pieces } = this.props;
    const { selectedIdx } = this.state;

    this.setState({ selectedIdx: (pieces.length + selectedIdx - 1) % pieces.length });
  }

  goNext = () => {
    const { pieces } = this.props;
    const { selectedIdx } = this.state;

    this.setState({ selectedIdx: (selectedIdx + 1) % pieces.length });
  }

  touchBase = null
  translate = null
  touchStart = evt => this.touchBase = evt.targetTouches[0].clientX

  touchMove = evt => {
    const posX = evt.targetTouches[0].clientX;
    this.translate = posX - this.touchBase;
    this.setState({ translate: `${this.translate}px` })
  }

  touchEnd = () => {
    const { windowWidth } = this.props;

    const next = val => () => {
      setTimeout(() => {
        const { selectedIdx } = this.state;
        const { pieces } = this.props;
        this.translate = 0;
        const nextIdx = (selectedIdx + val + pieces.length) % pieces.length;
        this.setState({
          translate: 0,
          selectedIdx: nextIdx
        });
      }, 100);
    }

    if (!this.translate && (this.touchBase < 50 || this.touchBase > windowWidth - 50)) {
      this.translate = 1;
      const sign = this.touchBase < 40 ? 1 : -1;
      this.setState({ translate: `${100*sign}vw` }, next(-1*sign));
    }
    else if (Math.abs(this.translate) > 40) {
      const sign = Math.sign(this.translate);
      this.setState({ translate: `${100*sign}vw` }, next(-1*sign));
    }
    else {
      this.setState({ translate: 0 }, () => this.translate = 0)
    }
  }

  createImageContainer = (bound = 0) => {
    const { pieces } = this.props;
    const { selectedIdx } = this.state;

    const idx = (selectedIdx + bound + pieces.length) % pieces.length;
    const selPiece = pieces[idx] || {};

    return (
      <Fragment>
        <div
          className={styles.column}
        >
          <div className={styles.wrapper}>
            <Image
              className={styles.image}
              src={selPiece.images.fullUrl}
              alt={selPiece.name}
              noAnimation
            />
          </div>
          <div className={styles.info}>
            <div className={styles.title}>
              {`${selPiece.name}, ${selPiece.year}`}
            </div>
            <div className={styles.subtitle}>
              {`${selPiece.width} x ${selPiece.height} cm | ${selPiece.technique.name}`}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }

  render() {
    const { onEscape } = this.props;
    const { translate } = this.state;

    return (
      <div className={styles.screen} ref={this.targetRef}>
        <div className={styles.close} onClick={onEscape}>×</div>
          <div className={styles.leftArrow} onClick={this.goPrevious}>{`<`}</div>
          <div className={styles.rightArrow} onClick={this.goNext}>{`>`}</div>
        <div
          className={styles.scroller}
          onTouchStart={this.touchStart}
          onTouchMove={this.touchMove}
          onTouchEnd={this.touchEnd}
          style={{
            transform: `translateX(${translate})`,
            transitionDuration: this.translate !== 0 ? '' : '0s'
          }}
        >
          {this.createImageContainer(-1)}
          {this.createImageContainer()}
          {this.createImageContainer(1)}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ window }) => ({
  windowWidth: window.width
})

export default connect(mapStateToProps)(withRouter(FullscreenGallery));
