import React, { useEffect, useState } from 'react';

import fetch from 'utils/fetch';
import { urls } from 'utils/api';

import { Carousel } from 'components/';

import './Home.module.css';

function Home() {

  const [data, setData] = useState({});

  useEffect(() => {
    fetch(urls.home).then(data => setData(data[0].acf));
  }, []);


  const { images, display_duration, transition_duration } = data;

  const displayDuration = parseFloat(display_duration);
  const transitionDuration = parseFloat(transition_duration);

  return (
    <div>
      {images && (
        <Carousel
          gallerySlug={images[0].post_name}
          displayDuration={displayDuration}
          transitionDuration={transitionDuration}
        />
      )}
    </div>
  );
}

export default Home;
