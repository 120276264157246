import {
  UPDATE_VIEWPORT_SIZE,
  UPDATE_VIEWPORT_ORIENTATION
} from './constants';

const updateViewportSize = (width, height) => ({
  type: UPDATE_VIEWPORT_SIZE,
  payload: {
    width,
    height
  }
});

const updateViewportOrientation = (type, angle) => ({
  type: UPDATE_VIEWPORT_ORIENTATION,
  payload: {
    type,
    angle
  }
});

export {
  updateViewportSize,
  updateViewportOrientation
};
