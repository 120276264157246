import React, { Component } from 'react';

import  { urls } from 'utils/api';
import fetch from 'utils/fetch';

class Preloader extends Component {

  state = {
    ready: false
  }

  componentDidMount() {
    const { disabled } = this.props;
    if (disabled) {
      return;
    }

    const requests = [
      urls.home,
      urls.about,
      urls.exhibitions,
      urls.contact
    ];

    Promise
      .all(requests.map(url => fetch(url)))
      .then(() => this.setState({ ready: true }));
  }

  render() {
    const { disabled, children } = this.props;
    const { ready } = this.state;

    if (!ready && !disabled) {
      return <div>Loading</div>;
    }

    return children;
  }
}

export default Preloader;
