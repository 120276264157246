import {
  UPDATE_VIEWPORT_SIZE,
  UPDATE_VIEWPORT_ORIENTATION
} from './constants';

const windowInitialState = {
  width: 0,
  height: 0,
  orientation: null
}

const windowReducer = (state = windowInitialState, { type, payload }) => {
  switch (type) {
    case UPDATE_VIEWPORT_SIZE:
      return {
        ...state,
        width: payload.width,
        height: payload.height
      }
    case UPDATE_VIEWPORT_ORIENTATION:
    return {
      ...state,
      orientation: {
        type: payload.type,
        angle: payload.angle
      }
    }
    default:
      return state;
  }
};

export default windowReducer;
