import React, { Component, Fragment } from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { urls, baseUrl } from 'utils/api';
import fetch from 'utils/fetch';

import styles from './Carousel.module.css';
import animations from './animations.module.css';

class Carousel extends Component {

  static propTypes = {
    displayDuration: PropTypes.number,
    transitionDuration: PropTypes.number
  }

  state = {
    images: [],
    index: 0
  }

  componentDidMount() {
    const { gallerySlug } = this.props;

    fetch(`${urls.galleries}?slug=${gallerySlug}`)
      .then(this.prepareImages);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  prepareImages = data => {
    const { gallerySlug } = this.props;

    const carousel = data && data[gallerySlug];
    if (!carousel) {
      return;
    }

    const images = carousel.map(item => `${baseUrl}/${item.image_url}`);

    this.setState({ images });
  }

  incImageIndex = () => {
    const { index, images } = this.state;
    this.setState({
      index: (index + 1 + images.length) % images.length
    });
  }

  render() {
    const { images, index } = this.state;
    const { displayDuration = 5, transitionDuration = 2 } = this.props;

    return (
      <div className={styles.carousel}>
        {images.length > 0 && (
          <Fragment>
            <TransitionGroup className={styles.wrapper}>
              <CSSTransition
                key={index}
                timeout={displayDuration * 1000}
                classNames={{ ...animations }}
                appear={true}
                onEntered={this.incImageIndex}
              >
                <img
                  className={styles.image}
                  src={images[index]}
                  alt=""
                  style={{
                    transitionDuration: `${transitionDuration*2}s`
                  }}
                />
              </CSSTransition>
            </TransitionGroup>
            <div className={styles.squareCtn}>
              {images.map((image, idx) => (
                <div key={idx} className={cx(styles.square, {[styles.active]: idx === index})} />
              ))}
            </div>
          </Fragment>
        )}
      </div>
    );
  }

}

export default Carousel;
