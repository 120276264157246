/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useState, useEffect } from 'react';
import cx from 'classnames';

import fetch from 'utils/fetch';
import { urls } from 'utils/api';
import { useInputValues, validators } from './useInputValues';

import styles from './Contact.module.css';

function Contact() {

  const [data, setData] = useState({});

  useEffect(() => {
    fetch(urls.contact).then(data => setData(data[0].acf));
  }, []);


  const SEND_STATE = {
    INIT: 0,
    SENDING: 1,
    SENT: 2,
    FAILURE: 4
  };

  const [sendState, setSendState] = useState(SEND_STATE.INIT);

  const [inputs, setInput, validate] = useInputValues({
    'name': [validators.empty],
    'email': [validators.empty, validators.email],
    'message': [validators.empty]
  });

  const [errors, setErrors] = useState({});

  const sendEmail = () => {

    const errors = validate();
    setErrors(errors);

    if (errors.count) {
      return;
    }

    const formData = new FormData();
    formData.append('name', inputs['name']);
    formData.append('email', inputs['email']);
    formData.append('comments', inputs['message']);

    setSendState(SEND_STATE.SENDING);

    fetch(urls.email, {
      method: 'POST',
      body: formData
    }).then(res => setSendState(res.status > 200 ? SEND_STATE.FAILURE : SEND_STATE.SENT));
  }

  const disableFields = sendState > SEND_STATE.INIT;

  const sendMessageValue = () => {
    switch (sendState) {
      case SEND_STATE.INIT:
        return 'Send message';
      case SEND_STATE.SENDING:
        return 'Sending';
      case SEND_STATE.SENT:
        return 'Your message has been sent!';
      default:
        return 'Your message couldn\'t be delivered. Try again later.'
    }
  }

  return (
    <div className={styles.page}>
      <div className={styles.details}>
        <img src="https://empa.se/wordpress/wp-content/uploads/2019/04/Contact-Maija.png" alt="about" />
        <div>
          <div className={styles.title}>{data.title}</div>
          <div dangerouslySetInnerHTML={{ __html: data.content }} />
        </div>
        <div>
          <div className={styles.title}>Email</div>
          <a
            className={styles.email}
            href="#contact"
            data-info={data.email}
            onClick={evt => evt.preventDefault()}
          />
        </div>
      </div>
      <div className={styles.form}>
        <div className={styles.title}>Contact me</div>
        <div>
          <div className={styles.row}>
            <input
              className={cx({[styles.error]: !!errors.name})}
              type="text"
              placeholder="Name *"
              disabled={disableFields}
              onChange={(e) => setInput('name', e.target.value)}
            />
            <input
              className={cx({[styles.error]: !!errors.email})}
              type="text"
              placeholder="Email *"
              disabled={disableFields}
              onChange={(e) => setInput('email', e.target.value)}
            />
          </div>
          <textarea
            className={cx({[styles.error]: !!errors.message})}
            placeholder="Message *"
            disabled={disableFields}
            onChange={(e) => setInput('message', e.target.value)}
          />
          <div className={styles.send} onClick={sendEmail}>
            {sendMessageValue()}
          </div>
        </div>
      </div>
    </div>
  );

}

export default Contact;
