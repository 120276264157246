import React, { Component } from 'react';
import cx from 'classnames';

import { urls, baseUrl } from 'utils/api';
import fetch from 'utils/fetch';
import { Image } from 'components';

import styles from './About.module.css';
import './remote.css';

class About extends Component {

  state = {
    data: null,
    images: []
  }

  componentDidMount() {
    fetch(urls.about).then(this.prepareData);
  }

  prepareData = data => {
    if (!data || !data[0]) {
      return;
    }

    const post = data[0]['acf'];
    const gallerySlug = post['cv']['images'][0]['post_name'];

    return fetch(`${urls.galleries}?slug=${gallerySlug}`)
      .then(gallery => this.setState({
        data: post,
        images: gallery[gallerySlug]
      }));
  }

  render() {
    const { data, images } = this.state;

    const sections = !data ? [] : Object.keys(data).map(key => ({
      slug: key,
      ...data[key]
    }));

    return (
      <div className={styles.about}>
        <div className={styles.menu}>
          {sections.map(item => (
            <a href={`/about#${item.slug}`} key={item.title}>{item.title}</a>
          ))}
        </div>
        <div className={styles.body}>
          {sections.map((item, idx) => (
            <div key={item.title} className={cx(styles.section, { [styles.odd]: idx % 2 })}>
              <div id={item.slug} className={styles.anchor} />
              <div className={styles.row}>
                <div className={styles.cell}>
                  {item.image && (
                    <div className={styles.imageWrapper}>
                      <Image className={styles.image} src={item.image.url} alt="" />
                    </div>
                  )}
                  {item.images && (
                    <div className={styles.images}>{
                      images.map(image => (
                        <Image
                          key={image.id}
                          className={styles.image}
                          src={`${baseUrl}/${image.image_url}`} alt={image.alt}
                        />
                      ))
                    }
                    </div>
                  )}
                </div>
                <div className={cx(styles.cell, styles.content)}>
                  <div className={styles.title}>{item.title}</div>
                  {item.resume && <a href={item.resume.url} className={styles.resume}>⇪ Detailed CV</a>}
                  <div dangerouslySetInnerHTML={{__html: item.content}} />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

}

export default About;
