import { useEffect } from 'react';

export default function HundredVH() {

  const trackHeight = () => {
    document.documentElement.style.setProperty('--vh', `${window.innerHeight*0.01}px`);
  }

  useEffect(() => {
    trackHeight();
    window.addEventListener('resize', trackHeight);
    window.addEventListener('orientationchange', trackHeight);
    return () => {
      window.removeEventListener('resize', trackHeight);
      window.removeEventListener('orientationchange', trackHeight);
    }
  }, []);

  return null;
}
