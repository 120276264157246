import { useState } from 'react';

const validators = {
  empty: {
    func: val => val && val.length,
    message: 'Field can\'t be empty'
  },
  email: {
    func: val => {
      // eslint-disable-next-line no-useless-escape
      const reg = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return reg.test(val);
    },
    message: 'Invalid email address'
  }
};

function useInputValues(fields) {
  const [inputs, setInput] = useState(Object.keys(fields).reduce((acc, val) => {
    acc[val] = '';
    return acc;
  }, {}));

  const valueChanged = (inputName, value) => {
    setInput({
      ...inputs,
      [inputName]: value
    });
  }

  const validate = () => {
    const errors = {
      count: 0
    };

    Object.keys(inputs).forEach(key => {
      fields[key].forEach(({ func, message }) => {
        if (!func(inputs[key])) {
          errors.count++;
          errors[key] = message;
        }
      })
    });

    return errors;
  }

  return [inputs, valueChanged, validate];
}

export {
  validators,
  useInputValues
};
